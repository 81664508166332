import React from "react"
import { Link, graphql } from "gatsby"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version

import Layout from "../components/layout"

const BlogPost = ({ data }) => {
  const categories = data.allMysqlServices.edges
  console.log("categories", categories)
  return (
    <Layout>
      <div className="rs-services services-style13 gray-bg6 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            {categories.map((itemcategory, index) => (
              <div className="col-xl-4 col-md-6 mb-20" key={index}>
                <div className="services-item">
                  <div className="serial-number"> 0{index + 1}</div>
                  <div className="services-icon">
                    <img src={itemcategory.node.image_url} alt="Images" />
                  </div>
                  <div className="services-text">
                    <h4 className="title">
                      <Link to={itemcategory.node.slug} itemProp="url">
                        {itemcategory.node.title}
                      </Link>
                    </h4>
                    <p className="services-txt">
                      {itemcategory.node.description
                        .split(" ")
                        .splice(0, 25)
                        .join(" ")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const ServicestQuery = graphql`
  query AllCategory($id: Int!) {
    allMysqlServices(filter: { category_id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          mysqlId
          description
          image_url1
        }
      }
    }
  }
`
